

const Footer = () => {
    
    
      
    return ( 
        <>
            <div className="footer">
               <p className="footer"> &copy; 2024 by $HATE. All rights reserved!</p> 
            </div>
        </>
    );
}
 
export default Footer;