import hate from "../assets/images/hate.png";
import logoone from "../assets/images/hatepic.png";
import telegram from "../assets/images/telegram.svg";
import x from "../assets/images/x.svg";


const Header = () => {

    return ( 
        <>
            <header className="header">
                <div className="wrapper">
                    <div className="header-container">
                        <div className="header-container-content">
                            <div className="dogwifcash">
                                <h1>HATEONSOL</h1>
                                <h2>Let's $HATE <br /> together!</h2>
                            </div>
                            <div className="dog">
                                <div className='twenty-conteiner'>
                                    <img className='dog-left' src={hate} alt="" />
                                    <img className='dog-right' src={logoone} />
                                </div>
                            </div>
                            <div className="social-conteiner">
                                <div className="social">
                                    <ul>
                                        <li><a href="https://t.me/hateonsol"><img src={telegram} alt="" /></a></li>
                                        <li className="social-last-item"><a href="https://x.com/hateonsol"><img src={x} alt="" /></a></li>
                                    </ul>
                                    <div>
                                        <a className="button" href="https://app.hel.io/x/hate">Enter Presale Now</a>
                                    </div>
                                    <div>
                                        <p>Powered <br />by Solana</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
 
export default Header;