import leo from "../assets/images/leo.png";

const Tokenomics = () => {
    return ( 
        <>
            <div className="tokenomics">
                <div className="wrapper">
                <div className="content-cont-dog">
                            <div className="content-dog">
                                <p className="content"> 
                                $HATE is a Memecoin movement<br />
                                We unite degens, who hate rug pulling <br />
                                scams and fraudulent projects<br />
                                We are here to save you<br />
                                And cultivate a love for $Hate<br />
                                <br />
                                $Hate with us to make the space a bit better!<br /> 
                                </p>  
                            </div>
                            <div className="content-img-dog">
                                <img src={leo} alt="" />
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}
 
export default Tokenomics;