import leo from "../assets/images/leodog.gif";
import tip from "../assets/images/tip.png";
import dowg from "../assets/images/dowg.svg";

const Content = () => {
    return ( 
        <> 
        <div className="contenttwo">
            <div className="wrapper">
                    <div className="content-container">
                        <div className="content-container-content">
                            <div className="content-cont-dog">
                                <div className="content-img-dog-mem">
                                    <img src={tip} alt="" />
                                </div>
                                <div className="content-dog">
                                    <p className="content content-text"> 
                                   <span className="content-color">Wen airdrop and token launch?</span> <br /><br />
                                    Token airdrop comes slightly after the presale is complete <br />
                                    With $HATE token launch simultaneously. <br />
                                    Follow the Announcements on TG and X channels <br />
                                    </p>  
                                </div>
                            </div>
                        </div>
                 </div>
            </div>
        </div>
        </>
    );
}
 
export default Content;